import React from 'react';
import github from '~/static/icons/github.svg';
import twitter from '~/static/icons/twitter.svg';
import dribbble from '~/static/icons/dribbble.svg';
import sge from '~/static/sge.svg';
import styled from 'styled-components'


const FooterText = styled.a `
      font-family: 'NB';
      font-size:16px;
      
`;


export default ({ scrollTop, title }) => (
  <footer>
    <div className="container grid">
      <div className="col">
        <nav>
          <ul>
            <li className="email">
              <FooterText><a
                className="link"
                href="mailto:perteraul@gmail.com?subject=Hello%20from%20your%20website"
              >
                Send me an email →
              </a>
              </FooterText>
            </li>
            <li>
                <a href="//dribbble.com/perte" title="Dribbble">
                <img src={dribbble} alt="Dribbble" /></a>
            </li>
            <li>
                <a href="//twitter.com/perte" title="Twitter">
                <img src={twitter} alt="Twitter" /></a>
            </li>
            <li>
                <a href="//github.com/perteraul" title="GitHub">
                <img src={github} alt="GitHub" /></a>
            </li>
          </ul>
        </nav>
      </div>
      <div className="col">
        <FooterText>
          &copy; 1995 - {new Date().getFullYear()} {title}, unless otherwise noted.
        </FooterText>
      </div>
    </div>
  </footer>
);
